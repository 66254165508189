import styled from "@emotion/styled";
import type { Hit } from "instantsearch.js";
import { useAtomValue } from "jotai";
import { padding } from "polished";
import * as React from "react";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { isContextAwareQueryAtom } from "@/features/SupportHub/atoms/atoms";
import InstantSearchHitExternalLink from "@/features/SupportHub/components/InstantSearchHitExternalLink";
import { convertHitUrlToSupportHubUrl } from "@/features/SupportHub/helpers/helpers";

const HitContainer = styled.div(({ theme }) => ({
  ...padding(0, 0, theme.spacing[16]),
}));

const HelpCenterArticleHitComponent = React.memo(({ hit }: { hit: Hit }) => {
  const trackEvent = useTrackEvent();
  const isContextAwareQuery = useAtomValue(isContextAwareQueryAtom);

  const url = React.useMemo(
    () => convertHitUrlToSupportHubUrl(hit.url),
    [hit.url],
  );

  const handleClick = React.useCallback(() => {
    trackEvent(AnalyticsEvents.SupportHubAlgoliaSearchArticleClicked, {
      article: hit.url,
      queryId: hit.__queryID,
      isFromContextAwareQuery: isContextAwareQuery,
    });
  }, [hit.__queryID, hit.url, isContextAwareQuery, trackEvent]);

  React.useEffect(() => {
    trackEvent(AnalyticsEvents.SupportHubAlgoliaSearchArticleShown, {
      article: hit.url,
      queryId: hit.__queryID,
      isFromContextAwareQuery: isContextAwareQuery,
    });
  }, [hit.__queryID, hit.url, isContextAwareQuery, trackEvent]);

  return (
    <HitContainer>
      <InstantSearchHitExternalLink
        title={hit.title}
        url={url}
        onClick={handleClick}
      />
    </HitContainer>
  );
});

export default HelpCenterArticleHitComponent;

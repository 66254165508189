import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";

const useWhatsappSupportUrl = () => {
  const { selectedCategory } = useSupportHub();

  return `https://wa.me/18556372227?text=${encodeURIComponent(
    selectedCategory
      ? `Hi, I have a question about ${selectedCategory?.name}:`
      : "Hi, I have a question:",
  )}`;
};

export default useWhatsappSupportUrl;

import { Text } from "@brexhq/metal-text";
import { useBreakpoint } from "@brexhq/metal-utils";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { padding } from "polished";
import * as React from "react";
import { FastImage } from "@/components/FastImage";

const Container = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: theme.spacing[16],
  ...padding(theme.spacing[20]),
  ...theme.borders.defaultBorder.bottom,
  background: theme.colors.background.primary,

  [theme.breakpoints.phone]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const ImageContainer = styled.div(({ theme }) => ({
  [theme.breakpoints.phone]: {
    alignSelf: "center",
    marginRight: 0,
  },
}));

const WrappedFastImage = styled(FastImage)(({ theme }) => ({
  img: {
    borderRadius: 8,
    // add default background for illustration if it doesn't have one
    backgroundColor: theme.colors.background.secondary,
  },
}));

const Content = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
}));

const TitleWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  color: theme.colors.content.primary,
  alignItems: "center",
}));

const RightContent = styled.div(({ theme }) => ({
  textAlign: "end",
  flexGrow: 0,
  [theme.breakpoints.phone]: {
    width: "100%",
  },
}));

type SupportHubCtaCardProps = {
  imageUrl: string;
  imageAlt: string;
  title: string;
  tag?: React.ReactNode;
  description: string;
  rightContent: React.ReactNode;
  imageStyleOverrides?: React.CSSProperties;
};

export const SupportHubCtaCard = ({
  imageUrl,
  imageAlt,
  title,
  tag,
  description,
  rightContent,
  imageStyleOverrides,
}: SupportHubCtaCardProps) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.phone);
  const imageSize = isMobile ? 120 : 50;

  return (
    <Container>
      <ImageContainer>
        <WrappedFastImage
          alt={imageAlt}
          width={imageSize}
          height={imageSize}
          src={imageUrl}
          {...imageStyleOverrides}
        />
      </ImageContainer>
      <Content>
        <TitleWrapper>
          <Text variant="BodyLargeSB">{title}</Text>
          {tag}
        </TitleWrapper>
        <Text variant="BodySmallR" as="div">
          {description}
        </Text>
      </Content>
      <RightContent>{rightContent}</RightContent>
    </Container>
  );
};

export default SupportHubCtaCard;

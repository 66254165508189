import { VStack } from "@brexhq/metal-layout";
import { Text } from "@brexhq/metal-text";
import * as React from "react";

type DetailsPaneContentProps = {
  subtitle: string;
  children: React.ReactNode;
};

const DetailsPaneContent: React.VFC<DetailsPaneContentProps> = ({
  subtitle,
  children,
}) => {
  return (
    <VStack spacing={16}>
      <Text variant="BodySmallR">{subtitle}</Text>
      {children}
    </VStack>
  );
};

export default DetailsPaneContent;

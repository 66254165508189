import styled from "@emotion/styled";
import { padding, margin } from "polished";

export const SearchContainer = styled.div(({ theme }) => ({
  ...padding(theme.spacing[16], theme.spacing[20]),
  ...margin(0, 0, theme.spacing[4]),
  background: theme.colors.background.primary,
  ...theme.borders.defaultBorder.top,
  ...theme.borders.defaultBorder.bottom,
}));

export const SearchInputContainer = styled.div(() => ({
  ...margin(0),
}));

export const ArticleListContainer = styled.div(() => ({
  ...padding(0),
}));

export const ArticleList = styled.ul(({ theme }) => ({
  listStyle: "none",
  ...margin(0),
  ...padding(theme.spacing[16], 0, 0),
}));

export const TopArticle = styled.li(({ theme }) => ({
  ...margin(0, 0, theme.spacing[8]),
}));

export const AlgoliaSearchResults = styled.div(({ theme }) => ({
  marginTop: theme.spacing[16],
}));

import algoliaSearch from "algoliasearch/lite";
import type { SearchClient } from "algoliasearch/lite";
import * as React from "react";
import { FeedbackSource } from "@/__generated__/globalTypes";
import type { SuggestionFormDefaultValues } from "@/features/CustomerFeedback/sharedTypes";
import type {
  TransactionSummary,
  ExpenseSummary,
} from "@/features/IssueManagement/sharedTypes";
import type {
  GetSupportHubDataQuery_helpCenterCategories_HelpCenterCategory as GetSupportHubDataQuery_helpCenterCategories,
  GetSupportHubDataQuery_helpCenterCategories_HelpCenterCategory_topArticles_HelpCenterArticle as GetSupportHubDataQuery_helpCenterCategories_topArticles,
  GetSupportHubDataQuery_supportChannels_SupportChannels as GetSupportHubDataQuery_supportChannels,
  GetSupportHubDataQuery_representative_Representative as GetSupportHubDataQuery_representative,
} from "@/features/SupportHub/data/__generated__/queries.generated";
import type { SupportHubView } from "@/features/SupportHub/sharedTypes";
import { getEnvironment } from "@/helpers";
import noOp from "@/helpers/noOp";

export type SupportHubContextValue = {
  categories: GetSupportHubDataQuery_helpCenterCategories[];
  setCategories?: (categoryList: []) => void;
  selectedCategoryPath: string | null;
  alert: string;
  setAlert?: (prompt: string) => void;
  alertCategory: string | null;
  setAlertCategory?: (category: string | null) => void;
  alertArticle: GetSupportHubDataQuery_helpCenterCategories_topArticles | null;
  setAlertArticle?: (
    article: GetSupportHubDataQuery_helpCenterCategories_topArticles | null,
  ) => void;
  selectedCategory: GetSupportHubDataQuery_helpCenterCategories | null;
  setSelectedCategory?: (category: string | null, fromAlert?: boolean) => void;
  visibleArticles:
    | (GetSupportHubDataQuery_helpCenterCategories_topArticles | null)[]
    | null;
  defaultSupportChannels: GetSupportHubDataQuery_supportChannels | null;
  setDefaultSupportChannels?: (
    supportChannels: GetSupportHubDataQuery_supportChannels,
  ) => void;
  representative: GetSupportHubDataQuery_representative | null;
  setRepresentative?: (
    representative: GetSupportHubDataQuery_representative | null,
  ) => void;
  suggestionFormDefaultValues: SuggestionFormDefaultValues | null;
  setSuggestionFormDefaultValues: (
    defaultValues: SuggestionFormDefaultValues | null,
  ) => void;
  resetSuggestionFormValues: () => void;
  createIssueTransaction: null | TransactionSummary;
  setCreateIssueTransaction: (transaction: TransactionSummary | null) => void;
  createIssueExpense: null | ExpenseSummary;
  setCreateIssueExpense: (transaction: ExpenseSummary | null) => void;
  supportHubView: SupportHubView;
  setSupportHubView: (view: SupportHubView) => void;
  searchClient?: SearchClient;
  helpCenterIndexName: string;
};

export const defaultSupportHubValues = {
  categories: [],
  selectedCategoryPath: null,
  alert: "",
  alertCategory: null,
  alertArticle: null,
  selectedCategory: null,
  visibleArticles: [],
  defaultSupportChannels: null,
  representative: null,
  suggestionFormDefaultValues: null,
  setSuggestionFormDefaultValues: noOp,
  resetSuggestionFormValues: noOp,
  createIssueTransaction: null,
  setCreateIssueTransaction: noOp,
  createIssueExpense: null,
  setCreateIssueExpense: noOp,
  supportHubView: "home" as SupportHubView,
  setSupportHubView: noOp,
  searchClient: undefined,
  helpCenterIndexName: "",
};

export const SupportHubContext = React.createContext<SupportHubContextValue>(
  defaultSupportHubValues,
);

export const SupportHubProvider: React.FC = React.memo(({ children }) => {
  const [supportHubView, setSupportHubView] =
    React.useState<SupportHubView>("home");

  const [categories, setCategories] = React.useState<
    GetSupportHubDataQuery_helpCenterCategories[]
  >([]);

  const [alert, setAlert] = React.useState<string>("");

  const [alertCategory, setAlertCategory] = React.useState<string | null>(null);

  const [alertArticle, setAlertArticle] =
    React.useState<GetSupportHubDataQuery_helpCenterCategories_topArticles | null>(
      null,
    );

  const [representative, setRepresentative] =
    React.useState<GetSupportHubDataQuery_representative | null>(null);

  const [defaultSupportChannels, setDefaultSupportChannels] =
    React.useState<GetSupportHubDataQuery_supportChannels | null>(null);

  const [selectedCategoryPath, setSelectedCategoryPath] = React.useState<
    string | null
  >(null);

  const [categorySelectedFromAlert, setCategorySelectedFromAlert] =
    React.useState(false);

  const setSelectedCategory = React.useCallback(
    (categoryPath: string | null, fromAlert = false) => {
      setSelectedCategoryPath(categoryPath);
      setCategorySelectedFromAlert(fromAlert);
    },
    [setSelectedCategoryPath, setCategorySelectedFromAlert],
  );

  const selectedCategory = React.useMemo(() => {
    return (
      categories.find((category) => category.path === selectedCategoryPath) ??
      null
    );
  }, [selectedCategoryPath, categories]);

  const visibleArticles = React.useMemo(() => {
    if (selectedCategory) {
      if (categorySelectedFromAlert && alertArticle) {
        return [alertArticle];
      }
      return selectedCategory.topArticles;
    }

    return [];
  }, [selectedCategory, categorySelectedFromAlert, alertArticle]);

  const [suggestionFormDefaultValues, setSuggestionFormDefaultValues] =
    React.useState<SuggestionFormDefaultValues | null>(null);

  const resetSuggestionFormValues = React.useCallback(() => {
    setSuggestionFormDefaultValues({
      subjectLine: "",
      response: "",
      feedbackSource: FeedbackSource.FEEDBACK_SOURCE_SUPPORT_HUB,
    });
  }, [setSuggestionFormDefaultValues]);

  const [createIssueTransaction, setCreateIssueTransaction] =
    React.useState<TransactionSummary | null>(null);

  const [createIssueExpense, setCreateIssueExpense] =
    React.useState<ExpenseSummary | null>(null);

  /**
   * Algolia Help Center Search
   */
  const algoliaSearchClient = algoliaSearch(
    getEnvironment("ALGOLIA_APP_ID") as string,
    getEnvironment("ALGOLIA_SEARCH_ONLY_KEY") as string,
  );

  const helpCenterIndexName = "helpCenter";

  const searchClient: SearchClient = React.useMemo(() => {
    return {
      ...algoliaSearchClient,
      search(requests) {
        return algoliaSearchClient.search([
          {
            indexName: helpCenterIndexName,
            params: {
              ...requests[0].params,
              clickAnalytics: true,
            },
          },
        ]);
      },
    };
  }, [algoliaSearchClient]);

  const supportHubStore = React.useMemo(
    () => ({
      categories,
      setCategories,
      selectedCategoryPath,
      alert,
      setAlert,
      alertCategory,
      setAlertCategory,
      alertArticle,
      setAlertArticle,
      selectedCategory,
      setSelectedCategory,
      visibleArticles,
      defaultSupportChannels,
      setDefaultSupportChannels,
      representative,
      setRepresentative,
      suggestionFormDefaultValues,
      setSuggestionFormDefaultValues,
      resetSuggestionFormValues,
      createIssueTransaction,
      setCreateIssueTransaction,
      createIssueExpense,
      setCreateIssueExpense,
      supportHubView,
      setSupportHubView,
      searchClient,
      helpCenterIndexName,
    }),
    [
      categories,
      setCategories,
      selectedCategoryPath,
      alert,
      setAlert,
      alertCategory,
      setAlertCategory,
      alertArticle,
      setAlertArticle,
      selectedCategory,
      setSelectedCategory,
      visibleArticles,
      defaultSupportChannels,
      setDefaultSupportChannels,
      representative,
      setRepresentative,
      suggestionFormDefaultValues,
      setSuggestionFormDefaultValues,
      resetSuggestionFormValues,
      createIssueTransaction,
      setCreateIssueTransaction,
      createIssueExpense,
      setCreateIssueExpense,
      supportHubView,
      setSupportHubView,
      searchClient,
      helpCenterIndexName,
    ],
  );

  return (
    <SupportHubContext.Provider value={supportHubStore}>
      {children}
    </SupportHubContext.Provider>
  );
});

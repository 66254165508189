import qs from "qs";
import * as React from "react";
import type { Location } from "react-router-dom";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { createMockableHook } from "@/helpers/createMockableHook";
import { urls } from "@/helpers/urls";

const getReportsInitialQuery = (search: string) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });

  return "Reporting " + params.id;
};

const getLastSegmentString = (pathname: string) => {
  return pathname
    .substring(pathname.lastIndexOf("/") + 1)
    .split("?")[0]
    .replace(/-/g, " ");
};

const getDefaultQuery = (pathname: string) => {
  const paths = pathname.split("/");
  let rootIndex = 0;

  if (paths[1] === "p" && paths.length > 2) {
    rootIndex = 2;
  } else {
    rootIndex = 1;
  }

  if (rootIndex === paths.length - 1) {
    return paths[rootIndex];
  }
  return paths[rootIndex] + " " + getLastSegmentString(pathname);
};

const useInitialAlgoliaQuery = (location: Location) => {
  const { supportHubUseAlgoliaSearch } = useFeatureFlags();

  const initialQuery = React.useMemo(() => {
    try {
      if (!supportHubUseAlgoliaSearch || !location) {
        return "";
      }

      const { pathname, search } = location;

      if (pathname.startsWith(urls.reports.root())) {
        return getReportsInitialQuery(search);
      }

      return getDefaultQuery(pathname);
    } catch (err) {
      console.error("Couldn't parse pathname for initial query", err);
      return "";
    }
  }, [location, supportHubUseAlgoliaSearch]);

  return initialQuery;
};

export const [useMockableInitialAlgoliaQuery, MockInitialAlgoliaQueryProvider] =
  createMockableHook(useInitialAlgoliaQuery);

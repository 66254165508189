import * as React from "react";
import { useQuery } from "@/features/Apollo";
import type { AccountingQuery } from "@/features/SupportHub/data/__generated__/queries.generated";
import { accountingQuery } from "@/features/SupportHub/data/queries";
import { connectionToNodes } from "@/helpers";
import { activeIntegrationStatuses } from "@/helpers/constants";
import { createMockableHook } from "@/helpers/createMockableHook";

export const useHasAccountingDataQuery = () => {
  const { data, error, loading } = useQuery<AccountingQuery>(accountingQuery, {
    fetchPolicy: "cache-and-network",
    variables: { activeIntegrationStatuses },
  });

  const hasAccountingIntegrationSetup = React.useMemo(() => {
    const integrations = connectionToNodes(data?.integrations);
    const connectedIntegration = integrations.find(
      (integration) => integration.status !== "disabled",
    );
    return connectedIntegration?.id || false;
  }, [data]);

  return {
    hasAccountingIntegrationSetup,
    hasError: !!error,
    isLoading: loading,
  };
};

export const [useHasAccountingData, MockedAccountingDataProvider] =
  createMockableHook(useHasAccountingDataQuery);

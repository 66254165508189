import {
  Banner,
  Breadcrumbs,
  DetailsPane,
  PrimaryButton,
} from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { addToast } from "@brexhq/metal-toast";
import styled from "@emotion/styled";
import * as React from "react";
import { FeedbackSource } from "@/__generated__/globalTypes";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { useMainLayout } from "@/domains/App/features/MainLayout/contexts/MainLayoutContext";
import SuggestionForm from "@/features/CustomerFeedback/components/SuggestionForm";
import useSuggestionForm from "@/features/CustomerFeedback/hooks/useSuggestionForm";
import DetailsPaneContent from "@/features/SupportHub/components/DetailsPaneContent";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";
import type { HomeBreadCrumb } from "@/features/SupportHub/sharedTypes";

type ShareSuggestionControllerProps = {
  homeBreadCrumb: HomeBreadCrumb;
  onClose?: () => void;
};

const BreadCrumbWrapper = styled.div(({ theme }) => ({
  div: {
    ...theme.typography.BodyLargeSB,
  },
}));

const ShareSuggestionController: React.VFC<ShareSuggestionControllerProps> = ({
  homeBreadCrumb,
  onClose,
}) => {
  const { t } = useTranslation();

  const [hasError, setHasError] = React.useState<boolean>(false);

  const { hideSupportHub } = useMainLayout();

  const { categories, suggestionFormDefaultValues, resetSuggestionFormValues } =
    useSupportHub();

  React.useEffect(() => {
    // anytime the form is unmounted, we want to reset the values
    return () => {
      resetSuggestionFormValues();
    };
  }, [resetSuggestionFormValues]);

  const handleOnError = React.useCallback(() => {
    setHasError(true);
  }, [setHasError]);

  const handleOnSuccess = React.useCallback(() => {
    setHasError(false);
    addToast({
      type: "success",
      title: t("thank-you-for-your-suggestion"),
    });
    hideSupportHub();
  }, [t, hideSupportHub]);

  const {
    handleOnSubmit: handleOnSuggestionFormSubmit,
    control: suggestionFormControl,
    loading,
  } = useSuggestionForm({
    onSuccess: handleOnSuccess,
    onError: handleOnError,
    defaultValues: suggestionFormDefaultValues,
  });

  const handleOnSubmit = React.useCallback(() => {
    setHasError(false);
    handleOnSuggestionFormSubmit();
  }, [setHasError, handleOnSuggestionFormSubmit]);

  const generateCategoriesForSuggestionTopics = React.useCallback(() => {
    return categories.map((value) => ({
      value: value.name,
      label: value.name,
    }));
  }, [categories]);

  return (
    <DetailsPane
      onClose={onClose}
      header={
        <BreadCrumbWrapper>
          <Breadcrumbs
            paths={[
              homeBreadCrumb,
              { id: "current", label: t("share-a-suggestion") },
            ]}
          />
        </BreadCrumbWrapper>
      }
      footer={
        <PrimaryButton isFullWidth loading={loading} onClick={handleOnSubmit}>
          {t("submit")}
        </PrimaryButton>
      }
    >
      <DetailsPaneContent
        subtitle={t(
          "tell-us-about-your-experience-with-brex-wed-love-to-hear-suggestions-about-how-to-better-serve-you",
        )}
      >
        <SuggestionForm
          loading={loading}
          control={suggestionFormControl}
          suggestionTopics={generateCategoriesForSuggestionTopics()}
          feedbackSource={
            suggestionFormDefaultValues?.feedbackSource ||
            FeedbackSource.FEEDBACK_SOURCE_SUPPORT_HUB
          }
        />
        {hasError && (
          <Banner variant="error">
            {t("something-went-wrong-please-try-again-or-contact-support")}
          </Banner>
        )}
      </DetailsPaneContent>
    </DetailsPane>
  );
};

export default withControllerPerformanceMetrics(ShareSuggestionController);

import { Modal } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { Text } from "@brexhq/metal-text";
import styled from "@emotion/styled";
import QRCode from "qrcode.react";
import * as React from "react";
import useWhatsappSupportUrl from "@/features/SupportHub/hooks/useWhatsappSupportUrl";

const QRCodeContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing[20],
}));

type WhatsAppModalProps = {
  onClose: () => void;
};

const WhatsAppModal: React.VFC<WhatsAppModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const whatsappSupportUrl = useWhatsappSupportUrl();

  return (
    <Modal
      title={t("chat-with-us-on-whats-app-mobile")}
      onClose={onClose}
      width={400}
    >
      <Text variant="BodyLargeR">
        {t(
          "scan-the-qr-code-to-start-a-whats-app-chat-with-brex-support-on-your-phone",
        )}
      </Text>
      <QRCodeContainer>
        <QRCode value={whatsappSupportUrl} size={180} />
      </QRCodeContainer>
    </Modal>
  );
};

export default WhatsAppModal;

import { LinkButton } from "@brexhq/component-library";
import { Trans, useTranslation } from "@brexhq/i18n";
import * as React from "react";
import { useInstantSearch } from "react-instantsearch";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { AlgoliaSearchResults } from "@/features/SupportHub/components/HelpCenterSearch.elements";

const HelpCenterSearchResultsBoundary = React.memo(({ children }) => {
  // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-display/react/#handling-no-results
  const { indexUiState, results } = useInstantSearch();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();

  const shouldShowNoResults = React.useMemo(
    () =>
      indexUiState.query &&
      indexUiState.query.length !== 0 &&
      !results.__isArtificial &&
      results.nbHits === 0,
    [indexUiState.query, results.__isArtificial, results.nbHits],
  );

  const handleClick = React.useCallback(() => {
    trackEvent(AnalyticsEvents.SupportHubAlgoliaSearchNoResultsLinkClicked);
  }, [trackEvent]);

  React.useEffect(() => {
    if (shouldShowNoResults) {
      trackEvent(AnalyticsEvents.SupportHubAlgoliaSearchNoResultsView);
    } else {
      trackEvent(AnalyticsEvents.SupportHubAlgoliaSearchResultsView, {
        query: indexUiState.query ?? "",
        queryId: results.queryID,
        nbHits: results.nbHits,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowNoResults, trackEvent]);

  if (shouldShowNoResults) {
    return (
      <AlgoliaSearchResults>
        <Trans
          i18nKey="no-search-results"
          t={t}
          components={[
            <LinkButton
              type="link"
              href="https://www.brex.com/support/"
              onClick={handleClick}
            />,
          ]}
        />
        <div hidden>{children}</div>
      </AlgoliaSearchResults>
    );
  }
  return <AlgoliaSearchResults>{children}</AlgoliaSearchResults>;
});

export default HelpCenterSearchResultsBoundary;

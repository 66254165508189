import gql from "graphql-tag";

export const authorizeCoBrowsingMutation = gql`
  mutation AuthorizeCoBrowsingMutation(
    $input: UpdateUserGlanceVisitorIdInput!
  ) {
    updateUserGlanceVisitorId(input: $input) {
      errors {
        code
        message
      }
    }
  }
`;

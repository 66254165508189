import { PrimaryButton, ButtonSize } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { useBreakpoint } from "@brexhq/metal-utils";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { margin } from "polished";
import * as React from "react";
import { useTrackEvent } from "@/domains/App";
import { AnalyticsEvents } from "@/features/SupportHub/analytics";
import SupportHubCtaCard from "@/features/SupportHub/components/SupportHubCtaCard";

const Container = styled.div(({ theme }) => ({
  ...margin(0, 0, theme.spacing[4]),
}));

type EssentialsHelpCenterCtaProps = {
  isBifurcated?: boolean;
};

export const EssentialsHelpCenterCta = ({
  isBifurcated,
}: EssentialsHelpCenterCtaProps) => {
  const { t } = useTranslation();

  const trackEvent = useTrackEvent();
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.phone);

  const onHelpCenterCtaClick = React.useCallback(() => {
    trackEvent(AnalyticsEvents.SupportHubEssentialsHelpCenterCtaClicked);
  }, [trackEvent]);

  return (
    <Container>
      <SupportHubCtaCard
        imageUrl="https://brand.brex.com/transform/bc7dfc84-305d-40d4-8e9e-2c14bb5033ab/Using-Budgets-and-Policies"
        imageAlt={t("essentials-customer-hub-cta-card-title")}
        imageStyleOverrides={{ objectFit: "cover", objectPosition: "10% 50%" }}
        title={t("essentials-customer-hub-cta-card-title")}
        description={t("essentials-customer-hub-cta-card-description", {
          isBifurcated,
        })}
        rightContent={
          <PrimaryButton
            href="https://www.brex.com/support/why-was-the-brex-interface-updated"
            onClick={onHelpCenterCtaClick}
            type="link"
            size={isMobile ? ButtonSize.MEDIUM : ButtonSize.SMALL}
          >
            {t("learn-more")}
          </PrimaryButton>
        }
      />
    </Container>
  );
};

export default EssentialsHelpCenterCta;

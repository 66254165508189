import { useUserAccountInfoQuery } from "@/domains/App/features/Navbar/hooks/useUserAccountInfoQuery";
import { selectUserAccountInfo } from "@/domains/App/features/Navbar/selectors/selectUserAccountInfo";
import { useMockableProductConfig } from "@/hooks/useProductConfig";

export const useShouldShowEssentialsHelpCenterCta = () => {
  const {
    config: {
      budgetNestingEnabled,
      basicExpenseManagementEnabled,
      walletAccessEnabled,
    },
  } = useMockableProductConfig();
  const { data: userAccountData } = useUserAccountInfoQuery();

  const { isAdmin } = selectUserAccountInfo(userAccountData);

  const shouldShowEssentialsHelpCenterCta =
    basicExpenseManagementEnabled &&
    walletAccessEnabled &&
    !budgetNestingEnabled &&
    isAdmin;

  // if its essentials bifurcated non admin, we don't want to show the existing customer hub cta
  const hideCustomerHubCta =
    shouldShowEssentialsHelpCenterCta || !budgetNestingEnabled;

  return { shouldShowEssentialsHelpCenterCta, hideCustomerHubCta };
};

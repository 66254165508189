import gql from "graphql-tag";
import { useQuery } from "@/features/Apollo";
import { createMockableHook } from "@/helpers/createMockableHook";

export const GetSupportHubDataQuery = gql`
  query GetSupportHubDataQuery {
    representative {
      firstName
      lastName
      type
      schedulingLink
      email {
        sla
        emailAddress
      }
      call {
        sla
        phoneNumber
      }
    }
    supportChannels {
      chat {
        enabled
        sla
        whatsappEnabled
        chatEnabled
      }
      call {
        sla
      }
      email {
        sla
      }
    }
    helpCenterCategories {
      name
      path
      topArticles {
        name
        path
      }
      supportChannels {
        chat {
          enabled
          sla
          whatsappEnabled
          chatEnabled
        }
        call {
          sla
        }
        email {
          sla
        }
      }
    }
  }
`;

const useGetAllHelpCenterCategoriesQuery = () =>
  useQuery(GetSupportHubDataQuery);

export const [
  useMockableGetAllHelpCenterCategoriesQuery,
  MockGetAllHelpCenterCategoriesQueryProvider,
] = createMockableHook(useGetAllHelpCenterCategoriesQuery);

export const accountingQuery = gql`
  query AccountingQuery($activeIntegrationStatuses: [String!]!) {
    integrations(status: $activeIntegrationStatuses, last: 1) {
      edges {
        node {
          id
          status
        }
      }
    }
  }
`;

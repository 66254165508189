import { PrimaryButton, SecondaryButton } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import styled from "@emotion/styled";
import { padding, margin } from "polished";
import * as React from "react";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";

const AlertContainer = styled.div(({ theme }) => ({
  ...padding(theme.spacing[24], theme.spacing[24]),
  ...margin(0, -theme.spacing[24], theme.spacing[16]),
}));

const AlertMessage = styled.span(({ theme }) => ({
  ...theme.typography.BodyLargeSB,
  ...margin(0, 0, theme.spacing[16]),
  display: "block",
}));

const ButtonContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

const StyledPrimaryButton = styled(PrimaryButton)(() => ({
  marginLeft: "4px",
  flexGrow: 1,
}));

const StyledSecondaryButton = styled(SecondaryButton)(() => ({
  marginRight: "4px",
  flexGrow: 1,
}));

type SupportHubAlertProps = {
  handleAccept: () => void;
  handleDecline: () => void;
  alertMessage: string;
};

const SupportHubAlert: React.FC<SupportHubAlertProps> = ({
  alertMessage,
  handleAccept,
  handleDecline,
}) => {
  const { t } = useTranslation();

  const { alertCategory, alertArticle } = useSupportHub();
  const trackEvent = useTrackEvent();

  const alertPayload = React.useMemo(() => {
    if (!alertCategory) {
      return null;
    }

    return {
      alert: alertMessage,
      alertCategory,
      alertArticle: alertArticle?.path ?? undefined,
    };
  }, [alertMessage, alertCategory, alertArticle]);

  const onAccept = () => {
    handleAccept();
    if (alertPayload) {
      trackEvent(AnalyticsEvents.SupportHubAlertAccepted, alertPayload);
    }
  };

  const onDecline = () => {
    handleDecline();

    if (alertPayload) {
      trackEvent(AnalyticsEvents.SupportHubAlertDeclined, alertPayload);
    }
  };

  React.useEffect(() => {
    if (alertPayload) {
      trackEvent(AnalyticsEvents.SupportHubAlertShown, alertPayload);
    }
  }, [alertPayload, trackEvent]);

  return (
    <AlertContainer>
      <AlertMessage>{alertMessage}</AlertMessage>
      <ButtonContainer>
        <StyledSecondaryButton onClick={onDecline}>
          {t("no")}
        </StyledSecondaryButton>
        <StyledPrimaryButton onClick={onAccept}>{t("yes")}</StyledPrimaryButton>
      </ButtonContainer>
    </AlertContainer>
  );
};

export default SupportHubAlert;

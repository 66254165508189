import { BaseStencil, DetailsPane } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { Text } from "@brexhq/metal-text";
import { useBreakpoint } from "@brexhq/metal-utils";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { margin } from "polished";
import * as React from "react";
import { UserRole } from "@/__generated__/globalTypes";
import { AnalyticsEvents, usePermissions, useTrackEvent } from "@/domains/App";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { useUserRole } from "@/features/Authentication/contexts/UserProperties";
import { useMockableGetImplementationTasks } from "@/features/ImplementationPanel/hooks/useGetImplementationTasks";
import { hasImplementationBeenCompleteForAWeek } from "@/features/ImplementationPanel/selectors";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import AlgoliaSearch from "@/features/SupportHub/components/AlgoliaSearch";
import CustomerHubCta from "@/features/SupportHub/components/CustomerHubCta";
import EssentialsHelpCenterCta from "@/features/SupportHub/components/EssentialsHelpCenterCta";
import HelpCenterArticleHitComponent from "@/features/SupportHub/components/HelpCenterArticleHitComponent";
import HelpCenterSearch from "@/features/SupportHub/components/HelpCenterSearch";
import RepresentativeDetails from "@/features/SupportHub/components/RepresentativeDetails";
import ShareSuggestion from "@/features/SupportHub/components/ShareSuggestion";
import SupportChannelListV3 from "@/features/SupportHub/components/SupportChannelListV3";
import { useMockableGetAllHelpCenterCategoriesQuery } from "@/features/SupportHub/data/queries";
import { useHasAccountingData } from "@/features/SupportHub/hooks/useHasAccountingData";
import { useShouldShowEssentialsHelpCenterCta } from "@/features/SupportHub/hooks/useShouldShowEssentialsHelpCenterCta";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";
import type { SupportHubView } from "@/features/SupportHub/sharedTypes";
import VerifyIdentityController from "@/features/VerifyIdentity/VerifyIdentityController";
import { useAMSPermissions } from "@/hooks/useAMSPermissions";
import { useBifurcatedBudgetFeatureFlags } from "@/hooks/useBifurcatedBudgetFeatureFlags";
import { useSandbox } from "@/modules/Sandbox/hooks/useSandbox";

const ImplementationPanelCalloutController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImplementationPanel" */
      "@/features/ImplementationPanel/controllers/ImplementationPanelCalloutController"
    ),
);

const StencilContainer = styled.div(({ theme }) => ({
  height: "80px",
  ...margin(theme.spacing[16], 0),
}));

type SupportHubHomeControllerProps = {
  onNavigateToView: (view: SupportHubView) => void;
  onClose?: () => void;
};

const SupportHubHomeController: React.VFC<SupportHubHomeControllerProps> = ({
  onNavigateToView,
  onClose,
}) => {
  const track = useTrackEvent();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.tabletPortrait);
  const { supportHubShowCustomerHubCta, supportHubUseAlgoliaSearch } =
    useFeatureFlags();

  const userRole = useUserRole();

  // TODO: temporary role check until we expand resource operations query
  const loggedInUserIsUma = React.useMemo(
    () => userRole === UserRole.USER_MANAGEMENT_ADMIN,
    [userRole],
  );

  const permissions = usePermissions();
  const { isInSandboxMode } = useSandbox();
  const { shouldSeeAMS, loading: amsLoading } = useAMSPermissions();

  const hasAMSInstead = shouldSeeAMS && !amsLoading;

  const canViewImplementation =
    !isMobile &&
    !hasAMSInstead &&
    permissions["primitives.implementation_panel.view"] &&
    !isInSandboxMode;

  const {
    setCategories,
    setDefaultSupportChannels,
    setRepresentative,
    searchClient,
    helpCenterIndexName,
  } = useSupportHub();

  const { loading: loadingCategories, data: categoryData } =
    useMockableGetAllHelpCenterCategoriesQuery();

  const { data: taskData, loading: loadingTasks } =
    useMockableGetImplementationTasks({
      skip: !canViewImplementation,
    });

  const { hasAccountingIntegrationSetup, isLoading: isAccountingLoading } =
    useHasAccountingData();

  const allTasksComplete =
    taskData?.implementationTasks?.completedTaskCount ===
    taskData?.implementationTasks?.totalTaskCount;

  const shouldShowCustomerHubCTA = React.useMemo(() => {
    // check for feature flag
    if (!supportHubShowCustomerHubCta) {
      return false;
    }
    // check if user has an accounting integration set up
    if (
      allTasksComplete &&
      !isAccountingLoading &&
      hasAccountingIntegrationSetup
    ) {
      return true;
    } else if (
      // check if its been 7 days since implementation completion
      !loadingTasks &&
      hasImplementationBeenCompleteForAWeek(
        taskData?.implementationTasks?.tasks,
      )
    ) {
      return true;
    }
    return false;
  }, [
    allTasksComplete,
    hasAccountingIntegrationSetup,
    isAccountingLoading,
    loadingTasks,
    supportHubShowCustomerHubCta,
    taskData?.implementationTasks?.tasks,
  ]);

  React.useEffect(() => {
    if (
      !loadingCategories &&
      categoryData?.helpCenterCategories &&
      categoryData?.supportChannels
    ) {
      setCategories?.(categoryData.helpCenterCategories);
      setDefaultSupportChannels?.(categoryData?.supportChannels);
      setRepresentative?.(categoryData?.representative ?? null);
    }
  }, [
    loadingCategories,
    categoryData,
    setCategories,
    setDefaultSupportChannels,
    setRepresentative,
  ]);

  const ImplementationTasks = (
    <React.Suspense
      fallback={
        <StencilContainer>
          <BaseStencil />
        </StencilContainer>
      }
    >
      <ImplementationPanelCalloutController
        onNavigateToView={onNavigateToView}
        loading={loadingTasks}
        completedTaskCount={taskData?.implementationTasks?.completedTaskCount}
        totalTaskCount={taskData?.implementationTasks?.totalTaskCount}
      />
    </React.Suspense>
  );

  const hasImplementationTasks =
    !!taskData?.implementationTasks?.totalTaskCount;

  const { shouldShowEssentialsHelpCenterCta, hideCustomerHubCta } =
    useShouldShowEssentialsHelpCenterCta();
  // Accounts with Bifurcated Budgets use the "Spend limit" terminology
  const isBifurcated = useBifurcatedBudgetFeatureFlags();

  return (
    <DetailsPane
      header={
        <Text variant="BodyLargeSB" data-testid="support-hub-home">
          {t("support")}
        </Text>
      }
      onClose={onClose}
      isEdgeToEdge
    >
      <>
        {shouldShowEssentialsHelpCenterCta && (
          <EssentialsHelpCenterCta isBifurcated={isBifurcated} />
        )}
        {canViewImplementation &&
          shouldShowCustomerHubCTA &&
          !hideCustomerHubCta && <CustomerHubCta loading={loadingTasks} />}

        {canViewImplementation &&
          hasImplementationTasks &&
          !shouldShowCustomerHubCTA &&
          ImplementationTasks}

        <VerifyIdentityController onComplete={onClose} />

        {supportHubUseAlgoliaSearch && searchClient ? (
          <AlgoliaSearch
            searchClient={searchClient}
            indexName={helpCenterIndexName}
            hitComponent={HelpCenterArticleHitComponent}
          />
        ) : (
          <HelpCenterSearch loading={loadingCategories} />
        )}

        {!loggedInUserIsUma && (
          <RepresentativeDetails loading={loadingCategories} />
        )}

        <SupportChannelListV3
          loading={loadingCategories}
          onSendMessageClick={() => onNavigateToView("messageSupport")}
        />

        <ShareSuggestion
          onClick={() => {
            onNavigateToView("suggestionForm");
            track(AnalyticsEvents.SupportHubShareSuggestionClicked);
          }}
        />
      </>
    </DetailsPane>
  );
};

export default withControllerPerformanceMetrics(SupportHubHomeController);

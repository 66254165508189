import {
  PrimaryButton,
  ButtonSize,
  BaseStencil,
} from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { useBreakpoint } from "@brexhq/metal-utils";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { margin } from "polished";
import * as React from "react";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import SupportHubCtaCard from "@/features/SupportHub/components/SupportHubCtaCard";

const StencilContainer = styled.div(({ theme }) => ({
  height: 144,
  width: "100%",
  ...margin(0, 0, theme.spacing[4]),
}));

const Container = styled.div(({ theme }) => ({
  ...margin(0, 0, theme.spacing[4]),
}));

type CustomerHubCtaProps = {
  loading?: boolean;
};

export const CustomerHubCta = ({ loading }: CustomerHubCtaProps) => {
  const { t } = useTranslation();

  const trackEvent = useTrackEvent();
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.phone);

  const onCustomerHubCtaClick = React.useCallback(() => {
    trackEvent(AnalyticsEvents.SupportHubCustomerHubCtaClicked);
  }, [trackEvent]);

  if (loading) {
    return (
      <StencilContainer>
        <BaseStencil />
      </StencilContainer>
    );
  } else {
    return (
      <Container>
        <SupportHubCtaCard
          imageUrl="https://brand.brex.com/transform/e23cf44c-c097-49da-9c68-ad58028fea1d/Create-a-Budget"
          imageAlt=""
          title={t("need-help-setting-up-brex")}
          description={t(
            "brex-empower-allows-you-to-add-users-streamline-accounting-set-up-budgets-and-get-everything-needed-to-set-you-up-for-success",
          )}
          rightContent={
            <PrimaryButton
              href="https://www.brex.com/customer-hub"
              onClick={onCustomerHubCtaClick}
              type="link"
              size={isMobile ? ButtonSize.MEDIUM : ButtonSize.SMALL}
            >
              {t("learn-more")}
            </PrimaryButton>
          }
        />
      </Container>
    );
  }
};

export default CustomerHubCta;

import type { VerificationNotificationStatus } from "@/__generated__/globalTypes";
import type { CaseAttachedObjectType } from "@/features/IssueManagement/sharedTypes";

export enum AnalyticsEvents {
  // Support hub open/close
  SupportHubOpened = "support_hub.opened",
  SupportHubClosed = "support_hub.closed",

  // Support Navigation Bar
  SupportNavigationOpened = "support_navigation.opened",
  SupportNavigationLiveChat = "support_navigation.live_chat",
  SupportNavigationHelpCenter = "support_navigation.help_center",
  SupportNavigationPhoneCall = "support_navigation.phone_call",

  // Support channels
  SupportHubChannelClicked = "support_hub.support_channel.clicked",

  // Algolia help center search
  SupportHubAlgoliaSearchArticleClicked = "support_hub.algolia_search.article.clicked",
  SupportHubAlgoliaSearchArticleShown = "support_hub.algolia_search.article.shown",
  SupportHubAlgoliaSearchInputFocused = "support_hub.algolia_search.input.focused",
  SupportHubAlgoliaSearchNoResultsLinkClicked = "support_hub.algolia_search.no_results.link.clicked",
  SupportHubAlgoliaSearchNoResultsView = "support_hub.algolia_search.no_results.view",
  SupportHubAlgoliaSearchResultsView = "support_hub.algolia_search.results.view",
  SupportHubAlgoliaSearchSeeMoreInHelpCenterClicked = "support_hub.algolia_search.see_more_in_help_center.clicked",
  SupportHubAlgoliaSearchValueChange = "support_hub.algolia_search.value.change",
  SupportHubAlgoliaSearchView = "support_hub.algolia_search.view",

  // Legacy help center search
  SupportHubHelpCenterSearchFocused = "support_hub.category_search.input.focus",
  SupportHubHelpCenterSearchValueChanged = "support_hub.category_search.input.value.change",
  SupportHubHelpCenterCategorySelected = "support_hub.category_search.input.category_selected",
  SupportHubHelpCenterCategoryLinkShown = "support_hub.category_search.category_link.shown",
  SupportHubHelpCenterCategoryLinkClicked = "support_hub.category_search.category_link.clicked",
  SupportHubHelpCenterArticleShown = "support_hub.category_search.article.shown",
  SupportHubHelpCenterArticleClicked = "support_hub.category_search.article.clicked",
  SupportHubPopularTopicClicked = "support_hub.popular_topic.clicked",

  // Support hub alerts
  SupportHubAlertShown = "support_hub.alert.shown",
  SupportHubAlertAccepted = "support_hub.alert.accepted",
  SupportHubAlertDeclined = "support_hub.alert.declined",

  // Support Hub share suggestion
  SupportHubShareSuggestionClicked = "support_hub.share_suggestion.clicked",
  SupportHubSuggestionFormBackButtonClicked = "support_hub.suggestion_form.back_button.clicked",

  // Customer hub CTA
  SupportHubCustomerHubCtaClicked = "support_hub.customer_hub_cta.clicked",
  SupportHubEssentialsHelpCenterCtaClicked = "support_hub.essentials_help_center_cta.clicked",

  // Issue management
  SupportHubCreateIssueSuccess = "support_hub.create_issue.success",
  SupportHubCreateIssueFailed = "support_hub.create_issue.failed",
  SupportHubCreateIssueSubmitForm = "support_hub.create_issue.submit_form",
  SupportHubCreateIssueFileUploadSuccess = "support_hub.create_issue.file_upload_success",
  SupportHubCreateIssueFileUploadError = "support_hub.create_issue.file_upload_error",
  SupportHubCreateIssueFormOpened = "support_hub.create_issue.form_opened",

  // Verify Identity (Verification Notifications)
  SupportHubVerificationNotificationShown = "support_hub.verification_notification.shown",
  SupportHubVerificationNotificationStatusUpdateClicked = "support_hub.verification_notification.status_update.clicked",
  SupportHubVerificationNotificationStatusUpdateSuccess = "support_hub.verification_notification.status_update.success",
  SupportHubVerificationNotificationsStatusUpdateFailed = "support_hub.verification_notification.status_update.failed",
}

export enum SupportChannelName {
  WhatsApp = "whatsapp",
  LiveChat = "livechat",
  Email = "email",
  Call = "call",
  SendMessage = "send message",
  EmailRepresentative = "email representative",
  CallRepresentative = "call representative",
  ScheduleCallRepresentative = "schedule call representative",
  TravelLiveChat = "travel-livechat",
  TravelEmail = "travel-email",
  TravelCall = "travel-call",
  AuthorizeCoBrowsing = "authorize co-browsing",
}

type EmptyPayload = {};

type SupportChannelPayload = {
  supportChannel: SupportChannelName;
  selectedCategory?: string;
};

type SupportHubAlertPayload = {
  alert: string;
  alertCategory: string;
  alertArticle?: string;
};

type HelpCenterCategoryPayload = {
  category: string;
};

type HelpCenterArticlePayload = {
  article: string;
  category?: string;
};

type AlgoliaSearchArticlePayload = {
  article: string;
  isFromContextAwareQuery: boolean;
  queryId?: string;
};

type AlgoliaSearchResultsViewPayload = {
  queryId?: string;
  query: string;
  nbHits: number;
};

type ObjectAttachedPayload = {
  hasObjectAttached: boolean;
  attachedObjectType?: CaseAttachedObjectType;
};

type CreateIssueFormSubmissionPayload = ObjectAttachedPayload & {
  numberOfFiles: number;
};

type VerificationNotificationsPayload = {
  id: string;
  status?: VerificationNotificationStatus;
};

export type EventSchemaMap = {
  // Support hub open/close
  [AnalyticsEvents.SupportHubOpened]: EmptyPayload;
  [AnalyticsEvents.SupportHubClosed]: EmptyPayload;

  // Support Nav Bar Events
  [AnalyticsEvents.SupportNavigationOpened]: EmptyPayload;
  [AnalyticsEvents.SupportNavigationLiveChat]: EmptyPayload;
  [AnalyticsEvents.SupportNavigationHelpCenter]: EmptyPayload;
  [AnalyticsEvents.SupportNavigationPhoneCall]: EmptyPayload;

  // Support channels
  [AnalyticsEvents.SupportHubChannelClicked]: SupportChannelPayload;

  // Algolia help center search
  [AnalyticsEvents.SupportHubAlgoliaSearchArticleClicked]: AlgoliaSearchArticlePayload;
  [AnalyticsEvents.SupportHubAlgoliaSearchArticleShown]: AlgoliaSearchArticlePayload;
  [AnalyticsEvents.SupportHubAlgoliaSearchInputFocused]: EmptyPayload;
  [AnalyticsEvents.SupportHubAlgoliaSearchNoResultsLinkClicked]: EmptyPayload;
  [AnalyticsEvents.SupportHubAlgoliaSearchNoResultsView]: EmptyPayload;
  [AnalyticsEvents.SupportHubAlgoliaSearchResultsView]: AlgoliaSearchResultsViewPayload;
  [AnalyticsEvents.SupportHubAlgoliaSearchSeeMoreInHelpCenterClicked]: EmptyPayload;
  [AnalyticsEvents.SupportHubAlgoliaSearchValueChange]: EmptyPayload;
  [AnalyticsEvents.SupportHubAlgoliaSearchView]: EmptyPayload;

  // Legacy help center search
  [AnalyticsEvents.SupportHubHelpCenterSearchFocused]: EmptyPayload;
  [AnalyticsEvents.SupportHubHelpCenterSearchValueChanged]: EmptyPayload;
  [AnalyticsEvents.SupportHubHelpCenterCategorySelected]: HelpCenterCategoryPayload;
  [AnalyticsEvents.SupportHubHelpCenterCategoryLinkShown]: HelpCenterCategoryPayload;
  [AnalyticsEvents.SupportHubHelpCenterCategoryLinkClicked]: HelpCenterCategoryPayload;
  [AnalyticsEvents.SupportHubHelpCenterArticleShown]: HelpCenterArticlePayload;
  [AnalyticsEvents.SupportHubHelpCenterArticleClicked]: HelpCenterArticlePayload;
  [AnalyticsEvents.SupportHubPopularTopicClicked]: HelpCenterCategoryPayload;

  // Support hub alerts
  [AnalyticsEvents.SupportHubAlertShown]: SupportHubAlertPayload;
  [AnalyticsEvents.SupportHubAlertAccepted]: SupportHubAlertPayload;
  [AnalyticsEvents.SupportHubAlertDeclined]: SupportHubAlertPayload;

  // Support Hub share suggestion
  [AnalyticsEvents.SupportHubShareSuggestionClicked]: EmptyPayload;
  [AnalyticsEvents.SupportHubSuggestionFormBackButtonClicked]: EmptyPayload;

  // Issue management
  [AnalyticsEvents.SupportHubCreateIssueSuccess]: CreateIssueFormSubmissionPayload;
  [AnalyticsEvents.SupportHubCreateIssueFailed]: CreateIssueFormSubmissionPayload;
  [AnalyticsEvents.SupportHubCreateIssueSubmitForm]: CreateIssueFormSubmissionPayload;
  [AnalyticsEvents.SupportHubCreateIssueFileUploadSuccess]: EmptyPayload;
  [AnalyticsEvents.SupportHubCreateIssueFileUploadError]: EmptyPayload;
  [AnalyticsEvents.SupportHubCreateIssueFormOpened]: ObjectAttachedPayload;

  // Customer hub CTA
  [AnalyticsEvents.SupportHubCustomerHubCtaClicked]: EmptyPayload;
  [AnalyticsEvents.SupportHubEssentialsHelpCenterCtaClicked]: EmptyPayload;

  // Verify Identity (Verification Notifications)
  [AnalyticsEvents.SupportHubVerificationNotificationShown]: VerificationNotificationsPayload;
  [AnalyticsEvents.SupportHubVerificationNotificationStatusUpdateClicked]: VerificationNotificationsPayload;
  [AnalyticsEvents.SupportHubVerificationNotificationStatusUpdateSuccess]: VerificationNotificationsPayload;
  [AnalyticsEvents.SupportHubVerificationNotificationsStatusUpdateFailed]: VerificationNotificationsPayload;
};

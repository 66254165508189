import {
  BaseStencil,
  ButtonSize,
  LinkButton,
  WhatsApp,
  CollapsiblePanel,
  SecondaryButton,
  DetailsSection,
} from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { HStack } from "@brexhq/metal-layout";
import { Text } from "@brexhq/metal-text";
import { useBreakpoint } from "@brexhq/metal-utils";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useGate } from "statsig-react";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { useAuthorize } from "@/domains/App/features/Permissions/hooks/useAuthorize";
import { useLiveSupport } from "@/features/Ada/hooks/useLiveSupport";
import { getGlanceVisitorId } from "@/features/Analytics/helpers/glance";
import { SupportChannelName } from "@/features/SupportHub/analytics";
import { SupportChannel } from "@/features/SupportHub/components/SupportChannel";
import WhatsAppModal from "@/features/SupportHub/components/WhatsAppModal";
import { useAuthorizeCoBrowsing } from "@/features/SupportHub/hooks/useAuthorizeCoBrowsing";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";
import useWhatsappSupportUrl from "@/features/SupportHub/hooks/useWhatsappSupportUrl";
import { CoBrowsingState } from "@/features/SupportHub/sharedTypes";
import TripVipLabel from "@/features/Travel/components/TripVipLabel/TripVipLabel";
import { useShouldSeeTravel } from "@/features/Travel/hooks/useShouldSeeTravel";
import { travelSupportClient } from "@/features/TravelSupport/TravelSupportController";
import {
  supportPhoneNumber,
  supportPhoneNumberLink,
  travelSupportPhoneNumber,
  travelSupportPhoneNumberLink,
  travelVipSupportUSPhoneNumber,
  travelVipSupportUSPhoneNumberLink,
  travelVipSupportGBPhoneNumber,
  travelVipSupportGBPhoneNumberLink,
  travelSupportEmail,
  travelVipSupportEmail,
  travelSupportEmailLink,
  travelVipSupportEmailLink,
} from "@/helpers/constants";

const SupportChannelButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing[8],
}));

const StencilContainer = styled.div({
  height: "80px",
});

type SupportChannelListProps = {
  loading?: boolean;
  onSendMessageClick: () => void;
};

const SupportChannelList: React.VFC<SupportChannelListProps> = ({
  loading,
  onSendMessageClick,
}) => {
  const { t } = useTranslation();

  const location = useLocation();

  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.tabletPortrait);

  /**
   * Hide general support channels if the user is on a travel route.
   * This is a fix for a recurring issue where users were contacting general support
   * instead of travel support when dealing with travel-related issues
   */
  const isGeneralSupportHidden = location.pathname.startsWith("/travel");

  const { selectedCategory, defaultSupportChannels } = useSupportHub();

  const supportChannels =
    selectedCategory?.supportChannels ?? defaultSupportChannels;

  const { contactSupport } = useLiveSupport();

  const trackEvent = useTrackEvent();

  const trackSupportChannelClick = React.useCallback(
    (supportChannel: SupportChannelName) => {
      trackEvent(AnalyticsEvents.SupportHubChannelClicked, {
        supportChannel,
        selectedCategory: selectedCategory?.path ?? undefined,
      });
    },
    [selectedCategory, trackEvent],
  );

  const onLiveTravelChatClick = React.useCallback(() => {
    void travelSupportClient.openChat();
    trackSupportChannelClick(SupportChannelName.TravelLiveChat);
  }, [trackSupportChannelClick]);

  const onLiveChatClick = React.useCallback(() => {
    contactSupport();
    trackSupportChannelClick(SupportChannelName.LiveChat);
  }, [trackSupportChannelClick, contactSupport]);

  const [isWhatsAppModalVisible, setIsWhatsAppModalVisible] =
    React.useState(false);

  const onWhatsAppClick = React.useCallback(() => {
    trackSupportChannelClick(SupportChannelName.WhatsApp);
    setIsWhatsAppModalVisible(true);
  }, [trackSupportChannelClick]);

  const whatsappSupportUrl = useWhatsappSupportUrl();

  const whatsappButtonProps = {
    icon: WhatsApp,
    size: ButtonSize.TINY,
    ...(isMobile
      ? { type: "link" as const, href: whatsappSupportUrl }
      : { onClick: onWhatsAppClick }),
  };

  const onWhatsAppModalClose = React.useCallback(() => {
    setIsWhatsAppModalVisible(false);
  }, [setIsWhatsAppModalVisible]);

  const handleSendMessageClick = React.useCallback(() => {
    trackSupportChannelClick(SupportChannelName.SendMessage);
    onSendMessageClick();
  }, [trackSupportChannelClick, onSendMessageClick]);

  const { value: coBrowsingEnabled } = useGate("co_browsing_gate");
  const glanceVisitorId = getGlanceVisitorId();
  const { authorizeCoBrowsing, coBrowsingAssistiveContent, coBrowsingState } =
    useAuthorizeCoBrowsing();

  const handleAuthorizeCoBrowsing = React.useCallback(() => {
    trackSupportChannelClick(SupportChannelName.AuthorizeCoBrowsing);
    void authorizeCoBrowsing();
  }, [authorizeCoBrowsing, trackSupportChannelClick]);

  const { canSeeTravelSupport } = useShouldSeeTravel();
  const authorize = useAuthorize();
  const hasTravelVipManagement = authorize("Travel.VIP_BOOKINGS.MANAGE");

  if (loading) {
    return (
      <StencilContainer>
        <BaseStencil />
      </StencilContainer>
    );
  }

  if (!supportChannels) {
    return null;
  }

  const showChatChannel =
    supportChannels.chat?.enabled === true &&
    supportChannels.chat?.sla !== null &&
    supportChannels.chat?.sla !== undefined;
  const showPhoneChannel =
    supportChannels.call?.sla !== null &&
    supportChannels.call?.sla !== undefined;
  const showMessageChannel =
    supportChannels.email?.sla !== null &&
    supportChannels.email?.sla !== undefined;

  return (
    <>
      {!isGeneralSupportHidden &&
        (showChatChannel || showPhoneChannel || showMessageChannel) && (
          <DetailsSection isEdgeToEdge>
            <CollapsiblePanel
              title={t("general-support")}
              defaultIsOpen
              isEdgeToEdge
            >
              {showChatChannel && (
                <SupportChannel
                  channelName={t("chat")}
                  sla={supportChannels.chat?.sla}
                  supportActions={
                    <SupportChannelButtonContainer>
                      {supportChannels.chat?.whatsappEnabled && (
                        <SecondaryButton {...whatsappButtonProps}>
                          {t("whats-app")}
                        </SecondaryButton>
                      )}
                      {supportChannels.chat?.chatEnabled && (
                        <SecondaryButton
                          icon="chatSupport"
                          size={ButtonSize.TINY}
                          onClick={onLiveChatClick}
                        >
                          {t("live-chat")}
                        </SecondaryButton>
                      )}
                    </SupportChannelButtonContainer>
                  }
                />
              )}
              {showPhoneChannel && (
                <SupportChannel
                  channelName={t("call-or-text")}
                  sla={supportChannels.call?.sla}
                  supportActions={
                    <SecondaryButton
                      href={supportPhoneNumberLink}
                      type="link"
                      icon="phone"
                      size={ButtonSize.TINY}
                      onClick={() =>
                        trackSupportChannelClick(SupportChannelName.Call)
                      }
                    >
                      {supportPhoneNumber}
                    </SecondaryButton>
                  }
                />
              )}
              {showMessageChannel && (
                <SupportChannel
                  channelName={t("email")}
                  sla={supportChannels.email?.sla}
                  supportActions={
                    <SupportChannelButtonContainer>
                      <SecondaryButton
                        onClick={handleSendMessageClick}
                        icon="email"
                        aria-label="Send a email to Brex Support"
                        size={ButtonSize.TINY}
                      >
                        {t("send-message-button")}
                      </SecondaryButton>
                    </SupportChannelButtonContainer>
                  }
                />
              )}
              {coBrowsingEnabled && !!glanceVisitorId && (
                <SupportChannel
                  assistiveContent={coBrowsingAssistiveContent.text}
                  assistiveContentVariant={coBrowsingAssistiveContent.variant}
                  channelDetail={t("co-browsing-detail")}
                  channelName={t("co-browsing")}
                  supportActions={
                    <SecondaryButton
                      onClick={handleAuthorizeCoBrowsing}
                      icon="computer"
                      aria-label="Authorize co-browsing"
                      size={ButtonSize.TINY}
                      loading={coBrowsingState === CoBrowsingState.LOADING}
                    >
                      {t("co-browsing-button")}
                    </SecondaryButton>
                  }
                />
              )}
            </CollapsiblePanel>
          </DetailsSection>
        )}
      {canSeeTravelSupport && (
        <DetailsSection isEdgeToEdge>
          <CollapsiblePanel
            title={
              <HStack spacing={8}>
                <Text variant="BodyLargeSB">{t("travel-support")}</Text>
                <TripVipLabel />
              </HStack>
            }
            defaultIsOpen
            isEdgeToEdge
          >
            <SupportChannel
              channelName={t("chat")}
              sla={1}
              supportActions={
                <SecondaryButton
                  onClick={onLiveTravelChatClick}
                  icon="chatSupport"
                  aria-label="Chat with Brex Travel Support"
                  size={ButtonSize.TINY}
                >
                  {t("live-chat")}
                </SecondaryButton>
              }
            />
            <SupportChannel
              channelName={t("call")}
              sla={1}
              supportActions={
                <HStack spacing={12}>
                  <SecondaryButton
                    href={
                      hasTravelVipManagement
                        ? travelVipSupportUSPhoneNumberLink
                        : travelSupportPhoneNumberLink
                    }
                    type="link"
                    icon="phone"
                    aria-label="Call Brex Travel Support"
                    size={ButtonSize.TINY}
                    onClick={() =>
                      trackSupportChannelClick(SupportChannelName.TravelCall)
                    }
                  >
                    {hasTravelVipManagement
                      ? travelVipSupportUSPhoneNumber
                      : travelSupportPhoneNumber}
                  </SecondaryButton>
                  {hasTravelVipManagement && (
                    <SecondaryButton
                      href={travelVipSupportGBPhoneNumberLink}
                      type="link"
                      icon="phone"
                      aria-label="Call Brex Travel Support (UK)"
                      size={ButtonSize.TINY}
                      onClick={() =>
                        trackSupportChannelClick(SupportChannelName.TravelCall)
                      }
                    >
                      {travelVipSupportGBPhoneNumber}
                    </SecondaryButton>
                  )}
                </HStack>
              }
            />
            <SupportChannel
              channelName={t("email")}
              sla={360}
              supportActions={
                <LinkButton
                  href={
                    hasTravelVipManagement
                      ? travelVipSupportEmailLink
                      : travelSupportEmailLink
                  }
                  type="link"
                  aria-label="Email Brex Travel Support"
                  onClick={() =>
                    trackSupportChannelClick(SupportChannelName.TravelEmail)
                  }
                >
                  {hasTravelVipManagement
                    ? travelVipSupportEmail
                    : travelSupportEmail}
                </LinkButton>
              }
            />
          </CollapsiblePanel>
        </DetailsSection>
      )}

      {isWhatsAppModalVisible && (
        <WhatsAppModal onClose={onWhatsAppModalClose} />
      )}
    </>
  );
};

export default SupportChannelList;

import type { Hit } from "instantsearch.js";
import type { SendEventForHits } from "instantsearch.js/es/lib/utils";

export type SupportHubView =
  | "home"
  | "suggestionForm"
  | "messageSupport"
  | "implementationPanel";

export type HomeBreadCrumb = {
  id: string;
  label: string;
  onClick: () => void;
};

export type HitComponent =
  | React.JSXElementConstructor<{
      hit: Hit<Record<string, any>>;
      sendEvent: SendEventForHits;
    }>
  | undefined;

export enum CoBrowsingState {
  ERROR = "ERROR",
  LOADING = "LOADING",
  READY = "READY",
  SUCCESS = "SUCCESS",
}

export type AssistiveContentVariant = "error" | "success";

import { BaseStencil, SuggestionInput } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { Icon } from "@brexhq/metal-icon";
import styled from "@emotion/styled";
import { useSetAtom } from "jotai";
import * as React from "react";
import { useSearchBox } from "react-instantsearch";
import { useDebounce } from "use-debounce";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { isContextAwareQueryAtom } from "@/features/SupportHub/atoms/atoms";

const StencilContainer = styled.div({
  height: 40,
});

type AlgoliaSearchInputProps = {
  initialAlgoliaQuery: string;
  loading?: boolean;
};

const AlgoliaSearchInput: React.VFC<AlgoliaSearchInputProps> = React.memo(
  ({ loading = false, initialAlgoliaQuery }) => {
    const { t } = useTranslation();
    const trackEvent = useTrackEvent();
    const { refine } = useSearchBox();
    const setIsContextAwareQuery = useSetAtom(isContextAwareQueryAtom);

    const [searchValue, setSearchValue] = React.useState("");
    const [debouncedSearchValue] = useDebounce(searchValue, 400);
    const [hasFocused, setHasFocused] = React.useState(false);

    const onChangeSearch = React.useCallback(
      ({ value }: { value: string }) => {
        setSearchValue(value);
      },
      [setSearchValue],
    );

    const onFocus = React.useCallback(() => {
      setHasFocused(true);
      trackEvent(AnalyticsEvents.SupportHubAlgoliaSearchInputFocused);
    }, [trackEvent]);

    React.useEffect(() => {
      if (hasFocused) {
        if (debouncedSearchValue === "") {
          setIsContextAwareQuery(true);
          refine(initialAlgoliaQuery);
        } else {
          setIsContextAwareQuery(false);
          refine(debouncedSearchValue);
        }

        trackEvent(AnalyticsEvents.SupportHubAlgoliaSearchValueChange);
      }
    }, [
      debouncedSearchValue,
      hasFocused,
      initialAlgoliaQuery,
      refine,
      setIsContextAwareQuery,
      trackEvent,
    ]);

    return loading ? (
      <StencilContainer>
        <BaseStencil />
      </StencilContainer>
    ) : (
      <SuggestionInput
        name="help-center-search"
        value={searchValue}
        onChange={onChangeSearch}
        onFocus={onFocus}
        placeholder={t("search-placeholder")}
        aria-label={t("search-placeholder")}
        beforeInput={<Icon name="search" size={16} />}
      />
    );
  },
);

export default AlgoliaSearchInput;

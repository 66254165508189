import { BaseStencil, SuggestionInput } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { Icon } from "@brexhq/metal-icon";
import styled from "@emotion/styled";
import * as React from "react";
import { useDebouncedCallback } from "use-debounce";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";

const StencilContainer = styled.div({
  height: 40,
});

type CategorySearchInputProps = {
  loading?: boolean;
};

const CategorySearchInput: React.VFC<CategorySearchInputProps> = ({
  loading = false,
}) => {
  const { t } = useTranslation();

  const { selectedCategory, setSelectedCategory, categories } = useSupportHub();

  const trackEvent = useTrackEvent();

  const [searchValue, setSearchValue] = React.useState("");

  const debouncedTrackValueChange = useDebouncedCallback(() => {
    trackEvent(AnalyticsEvents.SupportHubHelpCenterSearchValueChanged);
  }, 320);

  const onChangeSearch = React.useCallback(
    ({ value }: { value: string }) => {
      setSearchValue(value);
      debouncedTrackValueChange();
    },
    [setSearchValue, debouncedTrackValueChange],
  );

  React.useEffect(() => {
    if (selectedCategory) {
      setSearchValue(selectedCategory.name ?? "");
    }
  }, [selectedCategory]);

  const searchSuggestions = React.useMemo(
    () =>
      categories.map((category) => ({
        value: category.path,
        label: category.name,
        additionalProps: {
          categoryName: category.name,
          categoryPath: category.path,
        },
      })),
    [categories],
  );

  const onSelect = React.useCallback(
    ({
      additionalProps,
    }: {
      additionalProps?: {
        categoryName: string;
        categoryPath: string;
      };
    }) => {
      if (additionalProps) {
        setSearchValue(additionalProps.categoryName);
        setSelectedCategory?.(additionalProps.categoryPath);
        trackEvent(AnalyticsEvents.SupportHubHelpCenterCategorySelected, {
          category: additionalProps.categoryPath,
        });
      }
    },
    [setSelectedCategory, trackEvent],
  );

  const onFocus = React.useCallback(() => {
    trackEvent(AnalyticsEvents.SupportHubHelpCenterSearchFocused);
  }, [trackEvent]);

  return loading ? (
    <StencilContainer>
      <BaseStencil />
    </StencilContainer>
  ) : (
    <SuggestionInput
      name="topic-search"
      value={searchValue}
      suggestions={searchSuggestions}
      onChange={onChangeSearch}
      onSelect={onSelect}
      onFocus={onFocus}
      placeholder={t("browse-topics")}
      aria-label={t("browse-topics-2")}
      beforeInput={<Icon name="search" size={16} />}
    />
  );
};

export default CategorySearchInput;

import {
  BaseStencil,
  CollapsiblePanel,
  DetailsSection,
  LinkButton,
} from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import styled from "@emotion/styled";
import * as React from "react";
import { RepresentativeType } from "@/__generated__/globalTypes";
import RepresentativeScheduleLink from "@/components/RepresentativeScheduleLink/RepresentativeScheduleLink";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { SupportChannelName } from "@/features/SupportHub/analytics";
import { SupportChannel } from "@/features/SupportHub/components/SupportChannel";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";

const StencilContainer = styled.div({
  height: 80,
});

const RepInfoContainer = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing[16],
  ...theme.borders.defaultBorder.bottom,
}));

const RepName = styled.span(({ theme }) => ({
  ...theme.typography.BodyLargeSB,
  marginBottom: theme.spacing[4],
  display: "block",
}));

const RepDescription = styled.span(({ theme }) => ({
  ...theme.typography.BodySmallR,
  color: theme.colors.content.tertiary,
}));

type RepresentativeDetailsProps = {
  loading?: boolean;
};

const RepresentativeDetails: React.VFC<RepresentativeDetailsProps> = ({
  loading,
}) => {
  const { t } = useTranslation();

  const { selectedCategory, representative } = useSupportHub();

  const trackEvent = useTrackEvent();

  const trackSupportChannelClick = React.useCallback(
    (supportChannel: SupportChannelName) => {
      trackEvent(AnalyticsEvents.SupportHubChannelClicked, {
        supportChannel,
        selectedCategory: selectedCategory?.path ?? undefined,
      });
    },
    [selectedCategory, trackEvent],
  );

  const { repTitle, repDescription } = React.useMemo(() => {
    let title;
    let description;

    if (representative && representative.type !== RepresentativeType.INVALID) {
      description = t("contact-support-specialist-description", {
        firstName: representative.firstName,
      });

      switch (representative.type) {
        case RepresentativeType.DEDICATED_SUPPORT_SPECIALIST:
          title = t("contact-dedicated-support-specialist");
          break;
        case RepresentativeType.CUSTOMER_SUCCESS_MANAGER:
          title = t("contact-customer-success-manager");
          break;
        case RepresentativeType.RELATIONSHIP_MANAGER:
          title = t("contact-relationship-manager");
          break;
        case RepresentativeType.DEDICATED_SUPPORT_TEAM:
        default:
          title = t("contact-your-dedicated-support-team");
          description = t("contact-dedicated-support-team-description");
      }
    }

    return {
      repTitle: title,
      repDescription: description,
    };
  }, [representative, t]);

  if (loading) {
    return (
      <StencilContainer>
        <BaseStencil />
      </StencilContainer>
    );
  }

  if (
    !representative ||
    representative.type === RepresentativeType.INVALID ||
    !representative.schedulingLink
  ) {
    return null;
  }

  return (
    <>
      <DetailsSection isEdgeToEdge>
        <CollapsiblePanel title={repTitle} defaultIsOpen isEdgeToEdge>
          <RepInfoContainer>
            <RepName>
              {representative.firstName} {representative.lastName}
            </RepName>
            <RepDescription>{repDescription}</RepDescription>
          </RepInfoContainer>
          {representative.schedulingLink && (
            <SupportChannel
              channelName={t("schedule-a-meeting")}
              isPrimaryItem
              supportActions={
                <RepresentativeScheduleLink
                  schedulingLink={representative.schedulingLink}
                  onContinueClick={() => {
                    trackSupportChannelClick(
                      SupportChannelName.ScheduleCallRepresentative,
                    );
                  }}
                />
              }
            />
          )}
          {!!representative.call?.phoneNumber && (
            <SupportChannel
              channelName={t("call")}
              isPrimaryItem
              sla={representative.call.sla}
              supportActions={
                <LinkButton
                  href={`tel:${representative.call.phoneNumber}`}
                  type="link"
                  onClick={() =>
                    trackSupportChannelClick(
                      SupportChannelName.CallRepresentative,
                    )
                  }
                >
                  {representative.call.phoneNumber}
                </LinkButton>
              }
            />
          )}
          {representative.email?.emailAddress && (
            <SupportChannel
              channelName={t("email")}
              isPrimaryItem
              sla={representative.email.sla}
              supportActions={
                <LinkButton
                  href={`mailto:${representative.email.emailAddress}`}
                  type="link"
                  onClick={() =>
                    trackSupportChannelClick(
                      SupportChannelName.EmailRepresentative,
                    )
                  }
                >
                  {representative.email.emailAddress}
                </LinkButton>
              }
            />
          )}
        </CollapsiblePanel>
      </DetailsSection>
    </>
  );
};

export default RepresentativeDetails;

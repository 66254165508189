import { useTranslation, Trans } from "@brexhq/i18n";
import { Icon } from "@brexhq/metal-icon";
import { Text } from "@brexhq/metal-text";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { margin, padding } from "polished";
import * as React from "react";

type ShareSuggestionProps = {
  onClick: () => void;
};

const StyledButtonPanel = styled.div(({ theme }) => ({
  display: "flex",
  background: theme.colors.background.primary,
  ...theme.borders.defaultBorder.all,
  borderRadius: theme.borders.radius.default,
  ...padding(theme.spacing[16]),
  ...margin(theme.spacing[20]),
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: theme.spacing[16],
  cursor: "pointer",
}));

const ShareSuggestionContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const ShareSuggestionText = styled.div({
  display: "flex",
  flexDirection: "column",
});

const SuggestionIcon = styled.div(({ theme }) => ({
  marginRight: theme.spacing[8],
}));

const ShareSuggestion: React.VFC<ShareSuggestionProps> = ({ onClick }) => {
  const { t } = useTranslation();

  const { colors } = useTheme();
  return (
    <StyledButtonPanel onClick={onClick}>
      <ShareSuggestionContent>
        <SuggestionIcon>
          <Icon name="fileGenericDefault" size={16} />
        </SuggestionIcon>
        <ShareSuggestionText>
          <Trans
            i18nKey="x-0-share-a-suggestion-0-1-have-a-suggestion-on-how-to-improve-brex-1"
            t={t}
            components={[
              <Text variant="BodySmallSB" />,
              <Text variant="BodySmallR" color={colors.content.tertiary} />,
            ]}
          />
        </ShareSuggestionText>
      </ShareSuggestionContent>
    </StyledButtonPanel>
  );
};

export default ShareSuggestion;

import { useTranslation } from "@brexhq/i18n";
import * as React from "react";
import CategorySearchInput from "@/features/SupportHub/components/CategorySearchInput";
import {
  SearchContainer,
  SearchInputContainer,
  ArticleListContainer,
  ArticleList,
  TopArticle,
} from "@/features/SupportHub/components/HelpCenterSearch.elements";
import SupportExternalLink from "@/features/SupportHub/components/SupportExternalLink";
import SupportHubAlert from "@/features/SupportHub/components/SupportHubAlert";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";

type HelpCenterSearchProps = {
  loading?: boolean;
};

const HelpCenterSearch: React.VFC<HelpCenterSearchProps> = ({
  loading = false,
}) => {
  const { t } = useTranslation();

  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    alert,
    alertCategory,
    visibleArticles,
  } = useSupportHub();

  const [alertDismissed, setAlertDismissed] = React.useState(false);

  const handleAlertAccept = React.useCallback(() => {
    setSelectedCategory?.(alertCategory, true);
    setAlertDismissed(true);
  }, [setSelectedCategory, setAlertDismissed, alertCategory]);

  const handleAlertDeny = React.useCallback(() => {
    setAlertDismissed(true);
  }, [setAlertDismissed]);

  if (!loading && categories.length === 0) {
    return null;
  }

  return (
    <div>
      <SearchContainer>
        <SearchInputContainer>
          <CategorySearchInput loading={loading} />
        </SearchInputContainer>

        {selectedCategory && (
          <ArticleListContainer>
            <ArticleList>
              {visibleArticles?.map((article) => (
                <React.Fragment key={`hc-article-${article?.path}`}>
                  {article?.name && article?.path && (
                    <TopArticle>
                      <SupportExternalLink
                        text={article?.name}
                        path={article?.path}
                      />
                    </TopArticle>
                  )}
                </React.Fragment>
              ))}
            </ArticleList>

            <SupportExternalLink
              path={selectedCategory.path}
              text={t("see-more-in-help-center")}
              isCategory
            />
          </ArticleListContainer>
        )}
      </SearchContainer>
      {alert && alertCategory && !alertDismissed ? (
        <SupportHubAlert
          handleAccept={handleAlertAccept}
          handleDecline={handleAlertDeny}
          alertMessage={alert}
        />
      ) : null}
    </div>
  );
};

export default HelpCenterSearch;

import { LinkButton } from "@brexhq/component-library";
import { Icon } from "@brexhq/metal-icon";
import { Text } from "@brexhq/metal-text";
import styled from "@emotion/styled";
import * as React from "react";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";

type LinkProps = { highlight?: boolean };

const Link = styled(LinkButton)<LinkProps>(({ theme, highlight }) => ({
  ...(!highlight && { color: theme.colors.content.primary }),
}));

const LinkContent = styled.div({
  display: "flex",
  justifyContent: "flex-start",
});

const ExternalLinkIconWrapper = styled.div(({ theme }) => ({
  marginRight: theme.spacing[8],
  paddingTop: theme.spacing[2],
}));

type SupportExternalLinkProps =
  | {
      text: string;
      path: string;
      isCategory?: false;
    }
  | {
      text: string;
      path: string;
      isCategory: true;
    };

const SupportExternalLink: React.VFC<SupportExternalLinkProps> = (
  props: SupportExternalLinkProps,
) => {
  const { selectedCategory } = useSupportHub();
  const trackEvent = useTrackEvent();

  const handleClick = () => {
    if (props.isCategory) {
      trackEvent(AnalyticsEvents.SupportHubHelpCenterCategoryLinkClicked, {
        category: props.path,
      });
    } else {
      trackEvent(AnalyticsEvents.SupportHubHelpCenterArticleClicked, {
        article: props.path,
        category: selectedCategory?.path,
      });
    }
  };

  React.useEffect(() => {
    if (props.isCategory === true) {
      trackEvent(AnalyticsEvents.SupportHubHelpCenterCategoryLinkShown, {
        category: props.path,
      });
    } else {
      trackEvent(AnalyticsEvents.SupportHubHelpCenterArticleShown, {
        article: props.path,
        category: selectedCategory?.path,
      });
    }
  });

  return (
    <Link
      href={`https://www.brex.com/support/${
        props.path
      }?utm_source=support-hub&utm_term=${
        selectedCategory?.path ?? "no-category-selected"
      }`}
      type="link"
      highlight={props.isCategory}
      onClick={handleClick}
      inline
    >
      <LinkContent>
        <ExternalLinkIconWrapper>
          <Icon name="externalLink" size={16} />
        </ExternalLinkIconWrapper>
        <Text variant="BodySmallM">{props.text}</Text>
      </LinkContent>
    </Link>
  );
};

export default React.memo(SupportExternalLink);

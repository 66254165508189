import { Caret, IconDirection, IconSizes } from "@brexhq/component-library";
import { HStack, VStack } from "@brexhq/metal-layout";
import { Text } from "@brexhq/metal-text";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { padding } from "polished";
import * as React from "react";
import { SupportChannelSla } from "@/features/SupportHub/components/SupportChannelSla";
import type { AssistiveContentVariant } from "@/features/SupportHub/sharedTypes";

const SupportChannelContainer = styled.div<{ isPrimaryItem?: boolean }>(
  ({ as, theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...theme.borders.defaultBorder.bottom,
    ...padding(theme.spacing[12], 0),
    "&:first-of-type": {
      marginTop: -theme.spacing[16],
    },
    ...(as === "button" && {
      border: "none",
      width: "100%",
      background: "transparent",
      cursor: "pointer",
    }),
  }),
);

const SupportChannelName = styled.h3<{ isBold?: boolean }>(
  ({ isBold, theme }) => ({
    ...(isBold ? theme.typography.BodySmallSB : theme.typography.BodySmallR),
    margin: 0,
  }),
);

type SupportChannelAction =
  | {
      onClick: () => void;
      supportActions?: never;
    }
  | { onClick?: never; supportActions: React.ReactNode };

type SupportChannelProps = {
  assistiveContent?: string;
  assistiveContentVariant?: AssistiveContentVariant;
  channelDetail?: string;
  channelName: string;
  error?: string;
  icon?: React.ReactNode;
  isPrimaryItem?: boolean;
  sla?: number | null;
} & SupportChannelAction;

export const SupportChannel = ({
  assistiveContent,
  assistiveContentVariant,
  channelDetail,
  channelName,
  icon,
  isPrimaryItem,
  onClick,
  sla,
  supportActions,
}: SupportChannelProps) => {
  const theme = useTheme();

  return (
    <SupportChannelContainer
      as={onClick ? "button" : "div"}
      isPrimaryItem={isPrimaryItem}
      onClick={onClick}
    >
      <VStack>
        <HStack spacing={theme.spacing[8]} alignItems="center">
          {icon}
          <SupportChannelName isBold={isPrimaryItem}>
            {channelName}
          </SupportChannelName>
          <SupportChannelSla channelName={channelName} sla={sla} />
        </HStack>
        {channelDetail && (
          <Text variant="DetailLargeR" color={theme.colors.content.tertiary}>
            {channelDetail}
          </Text>
        )}
        {assistiveContent && (
          <Text
            variant="DetailLargeR"
            color={
              assistiveContentVariant === "error"
                ? theme.colors.negative.ink
                : theme.colors.positive.ink
            }
          >
            {assistiveContent}
          </Text>
        )}
      </VStack>
      {onClick ? (
        <Caret size={IconSizes.SMALL} direction={IconDirection.RIGHT} />
      ) : (
        <div>{supportActions}</div>
      )}
    </SupportChannelContainer>
  );
};

import { useTranslation } from "@brexhq/i18n";
import { useCallback, useMemo, useState } from "react";
import { useUserPropertiesForAnalytics } from "@/features/Analytics/contexts/Analytics";
import {
  getGlanceVisitorId,
  initializeGlance,
} from "@/features/Analytics/helpers/glance";
import { useMutation } from "@/features/Apollo";
import type {
  AuthorizeCoBrowsingMutation,
  AuthorizeCoBrowsingMutationVariables,
} from "@/features/SupportHub/data/__generated__/mutations.generated";
import { authorizeCoBrowsingMutation } from "@/features/SupportHub/data/mutations";
import type { AssistiveContentVariant } from "@/features/SupportHub/sharedTypes";
import { CoBrowsingState } from "@/features/SupportHub/sharedTypes";
import { internalTrackError } from "@/helpers/errorTracking";

export const useAuthorizeCoBrowsing = () => {
  const { t } = useTranslation();
  const { analyticsUserProperties } = useUserPropertiesForAnalytics();

  const [coBrowsingState, setCoBrowsingState] = useState<CoBrowsingState>(
    CoBrowsingState.READY,
  );

  const [mutate] = useMutation<
    AuthorizeCoBrowsingMutation,
    AuthorizeCoBrowsingMutationVariables
  >(authorizeCoBrowsingMutation, {
    onError: () => setCoBrowsingState(CoBrowsingState.ERROR),
  });

  const authorizeCoBrowsing = useCallback(async () => {
    setCoBrowsingState(CoBrowsingState.LOADING);
    try {
      initializeGlance(analyticsUserProperties?.email || undefined);

      const errors = await mutate({
        variables: {
          input: {
            glanceVisitorId: getGlanceVisitorId(),
          },
        },
      });

      if ((errors?.data?.updateUserGlanceVisitorId?.errors || []).length > 0) {
        setCoBrowsingState(CoBrowsingState.ERROR);
      } else {
        setCoBrowsingState(CoBrowsingState.SUCCESS);
      }
    } catch (error) {
      setCoBrowsingState(CoBrowsingState.ERROR);
      internalTrackError({
        errorName: "Failed to initialize Glance",
        error,
      });
    }
  }, [analyticsUserProperties?.email, mutate]);

  const coBrowsingAssistiveContent = useMemo<{
    text: string | undefined;
    variant: AssistiveContentVariant | undefined;
  }>(() => {
    if (coBrowsingState === CoBrowsingState.ERROR) {
      return {
        text: t("co-browsing-error"),
        variant: "error",
      };
    } else if (coBrowsingState === CoBrowsingState.SUCCESS) {
      return {
        text: t("co-browsing-success"),
        variant: "success",
      };
    }

    return {
      text: undefined,
      variant: undefined,
    };
  }, [coBrowsingState, t]);

  return {
    authorizeCoBrowsing,
    coBrowsingAssistiveContent,
    coBrowsingState,
  };
};

import { ButtonSize, SecondaryButton } from "@brexhq/component-library";
import { PageLayoutDetail } from "@brexhq/metal-navigation";
import { useBreakpoint } from "@brexhq/metal-utils";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useResetAtom } from "jotai/utils";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { SeenFlagKey } from "@/__generated__/globalTypes";
import { AnalyticsEvents, usePermissions, useTrackEvent } from "@/domains/App";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { MobileNavigation } from "@/domains/App/features/MainLayout/components/MobileNavigation";
import { useMainLayout } from "@/domains/App/features/MainLayout/contexts/MainLayoutContext";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import { useMockableGetImplementationTaskCount } from "@/features/ImplementationPanel/hooks/useGetImplementationTaskCount";
import MessageSupportController from "@/features/IssueManagement/controllers/MessageSupportController";
import { useMockableFspConfig } from "@/features/ProAccess";
import { useSeenFlag } from "@/features/SeenFlag";
import { isContextAwareQueryAtom } from "@/features/SupportHub/atoms/atoms";
import SuggestionFormController from "@/features/SupportHub/controllers/SuggestionFormController";
import SupportHubHomeController from "@/features/SupportHub/controllers/SupportHubHomeController";
import { useSupportHub } from "@/features/SupportHub/hooks/useSupportHub";
import type { SupportHubView } from "@/features/SupportHub/sharedTypes";
import { urls } from "@/helpers/urls";
import { useAMSPermissions } from "@/hooks/useAMSPermissions";
import { useLocalPersistedState } from "@/hooks/useLocalPersistedState";
import { LocalPersistedStateKeys } from "@/hooks/useLocalPersistedState/types";
import { useMockableProductConfig } from "@/hooks/useProductConfig";
import useQueryParams from "@/hooks/useQueryParams";
import { useSandbox } from "@/modules/Sandbox/hooks/useSandbox";

const ImplementationPanelController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImplementationPanel" */
      "@/features/ImplementationPanel/ImplementationPanelController"
    ),
);

const SUPPORT_HUB_BUTTON_ID = "support-hub-button";

const SupportNavContainer = styled.div({
  display: "flex",
  alignItems: "center",
});

interface Props {
  hasOverlay?: boolean;
}

const SupportHubController: React.VFC<Props> = ({ hasOverlay }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.tabletPortrait);

  const hasBeenClosed = React.useRef(false);

  const permissions = usePermissions();
  const { isInSandboxMode } = useSandbox();
  const {
    config: { accountSetupTasksEnabled },
  } = useMockableProductConfig();

  const {
    supportHubVisible,
    toggleSupportHub,
    showSupportHub,
    hideSupportHub,
  } = useMainLayout();

  const { shouldSeeAMS, loading: amsLoading } = useAMSPermissions();

  const hasAMSInstead = shouldSeeAMS || amsLoading;

  const canViewImplementation =
    accountSetupTasksEnabled &&
    !isMobile &&
    !isInSandboxMode &&
    !hasAMSInstead &&
    permissions["primitives.implementation_panel.view"];

  const { data } = useMockableGetImplementationTaskCount({
    skip:
      !canViewImplementation || (!supportHubVisible && hasBeenClosed.current),
  });

  const { isFsp, fspDetails } = useMockableFspConfig();

  const location = useLocation();
  const { queryParams, pushRemoveQueryParam, pushRemoveQueryParams } =
    useQueryParams();

  const {
    setSelectedCategory,
    supportHubView,
    setSupportHubView,
    setCreateIssueTransaction,
    setCreateIssueExpense,
  } = useSupportHub();

  const resetIsContextAwareQuery = useResetAtom(isContextAwareQueryAtom);

  const trackEvent = useTrackEvent();

  const onMenuButtonClick = React.useCallback(() => {
    trackEvent(AllAnalyticsEvents.SupportNavigationOpened);
    toggleSupportHub();
  }, [toggleSupportHub, trackEvent]);

  const closeSupportHub = React.useCallback(() => {
    hideSupportHub();
    setCreateIssueTransaction(null);
    setCreateIssueExpense(null);
    setSupportHubView("home");

    if (!hasBeenClosed.current) {
      hasBeenClosed.current = true;
    }
  }, [
    hideSupportHub,
    setCreateIssueTransaction,
    setCreateIssueExpense,
    setSupportHubView,
  ]);

  const closeImplementationPanel = React.useCallback(() => {
    closeSupportHub();
  }, [closeSupportHub]);

  const supportHubWasOpened = React.useRef(false);

  React.useEffect(() => {
    if (!supportHubVisible) {
      setSelectedCategory?.(null);
    }
  }, [supportHubVisible, setSelectedCategory]);

  React.useEffect(() => {
    if (supportHubVisible) {
      trackEvent(AnalyticsEvents.SupportHubOpened);
      if (!supportHubWasOpened.current) {
        supportHubWasOpened.current = true;
      }
    } else if (supportHubWasOpened.current) {
      resetIsContextAwareQuery();
      trackEvent(AnalyticsEvents.SupportHubClosed);
      const { support, source } = queryParams;
      if (support === "itp") {
        setSupportHubView("implementationPanel");
      } else {
        setSupportHubView("home");
      }

      if (support) {
        // We will remove both support and source if they both in the query params
        // source will be kept if it's being used somewhere else
        // remove cchno as it is coming from the idv comms
        if (source !== undefined) {
          pushRemoveQueryParams(["source", "support", "cchno"]);
        } else {
          pushRemoveQueryParam("support");
        }
      }
    }
  }, [
    supportHubVisible,
    trackEvent,
    setSupportHubView,
    pushRemoveQueryParam,
    queryParams,
    pushRemoveQueryParams,
    resetIsContextAwareQuery,
  ]);

  const { value: hasCompletedOnboarding } = useSeenFlag({
    key: SeenFlagKey.PRIMITIVES_ONBOARDING_COMPLETED,
  });

  const [stopForceOpenAccountSetup] = useLocalPersistedState(
    LocalPersistedStateKeys.STOP_ACCOUNT_SETUP_AUTO_OPEN,
    false,
  );

  // Show support hub and the view type based on certain conditions
  const shouldShowSupportHubView = React.useMemo(() => {
    const stillHasTasks = data?.completedTaskCount !== data?.totalTaskCount;

    // in the future we can use the SupportHubView type to determine which view to automatically open
    if (queryParams.support === "true") {
      setSupportHubView("home");
      return true;
    } else if (
      // Force the implementation panel open for admins etc who need to complete tasks still
      canViewImplementation &&
      (queryParams.support === "itp" ||
        (!hasBeenClosed.current &&
          stillHasTasks &&
          hasCompletedOnboarding &&
          !stopForceOpenAccountSetup)) &&
      !isFsp &&
      !fspDetails &&
      // We are running an experiment where we auto-direct people to the verification app on load. So do not open ITP in this scenario
      location.pathname !== urls.onboarding.verify() &&
      location.pathname !== urls.onboarding.productApplication()
    ) {
      setSupportHubView("implementationPanel");
      return true;
    }
    return false;
  }, [
    canViewImplementation,
    data?.completedTaskCount,
    data?.totalTaskCount,
    fspDetails,
    hasCompletedOnboarding,
    isFsp,
    queryParams.support,
    setSupportHubView,
    stopForceOpenAccountSetup,
    location.pathname,
  ]);

  React.useEffect(() => {
    if (shouldShowSupportHubView) {
      showSupportHub();
    }
  }, [shouldShowSupportHubView, showSupportHub]);

  const onNavigateToView = React.useCallback(
    (view: SupportHubView) => {
      setSupportHubView(view);
      setCreateIssueTransaction(null);
      setCreateIssueExpense(null);
    },
    [setSupportHubView, setCreateIssueTransaction, setCreateIssueExpense],
  );

  const homeBreadCrumb = React.useMemo(
    () => ({
      id: "home",
      label: "Support",
      onClick: () => onNavigateToView("home"),
    }),
    [onNavigateToView],
  );

  const getCurrentView = React.useCallback(
    (view: SupportHubView) => {
      switch (view) {
        case "home":
          return (
            <SupportHubHomeController
              onClose={!isMobile ? closeSupportHub : undefined}
              onNavigateToView={onNavigateToView}
            />
          );
        case "suggestionForm":
          return (
            <SuggestionFormController
              onClose={!isMobile ? closeSupportHub : undefined}
              homeBreadCrumb={homeBreadCrumb}
            />
          );
        case "messageSupport":
          return (
            <MessageSupportController
              onClose={!isMobile ? closeSupportHub : undefined}
              homeBreadCrumb={homeBreadCrumb}
              onMessageSent={closeSupportHub}
            />
          );
        case "implementationPanel":
          return (
            <React.Suspense fallback={null}>
              <ImplementationPanelController
                onClose={!isMobile ? closeImplementationPanel : undefined}
                homeBreadCrumb={homeBreadCrumb}
              />
            </React.Suspense>
          );
        default:
          return null;
      }
    },
    [
      isMobile,
      closeSupportHub,
      onNavigateToView,
      homeBreadCrumb,
      closeImplementationPanel,
    ],
  );

  return (
    <>
      <SecondaryButton
        size={ButtonSize.SMALL}
        icon="help"
        onClick={onMenuButtonClick}
        aria-label="support"
        id={SUPPORT_HUB_BUTTON_ID}
      >
        <SupportNavContainer>Support</SupportNavContainer>
      </SecondaryButton>
      {isMobile ? (
        <MobileNavigation
          collapsed={!supportHubVisible}
          toggleCollapsed={toggleSupportHub}
        >
          {getCurrentView(supportHubView)}
        </MobileNavigation>
      ) : (
        <PageLayoutDetail
          hasOverlay={hasOverlay}
          details={supportHubVisible && getCurrentView(supportHubView)}
          // This needs to be displayed overtop of the Takeover
          __unsafe_sidepanel_zIndex={theme.zIndex.sidepanelMask}
        />
      )}
    </>
  );
};

export default withControllerPerformanceMetrics(SupportHubController);

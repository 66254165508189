import { Status, StatusType, Tooltip } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { Icon } from "@brexhq/metal-icon";
import { Text } from "@brexhq/metal-text";
import styled from "@emotion/styled";
import { formatDuration, minutesToHours } from "date-fns";
import * as React from "react";

const TooltipContent = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing[8],
}));

type SupportChannelSlaProps = {
  sla?: number | null;
  channelName: string;
};

export const SupportChannelSla = ({
  sla,
  channelName,
}: SupportChannelSlaProps) => {
  const { t } = useTranslation();

  if (!sla) {
    return null;
  }

  const isChannelPrompt = channelName != t("email");
  const slaFormat =
    sla > 60 ? { hours: minutesToHours(sla) } : { minutes: sla };

  // Hacky way to shorten to hrs/mins. Will need to revisit for i18n
  const formattedSla = formatDuration(slaFormat)
    .replace("hour", "hr")
    .replace("minute", "min");

  return (
    <Tooltip
      unstable__content={
        <TooltipContent>
          <Icon name="clock" size={12} />
          <Text variant="DetailLargeM">{t("typical-response-time")}</Text>
        </TooltipContent>
      }
      maxWidth={200}
    >
      <Status
        status={isChannelPrompt ? StatusType.SUCCESS : StatusType.NEUTRAL}
        label={formattedSla}
        icon="clock"
        noWrap
      />
    </Tooltip>
  );
};
